import React from "react";

import { Container } from "reactstrap";

import customer from 'customer.json';

function Footer() {
  return (
    <>
      <footer className="footer">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href="https://www.smallneighborhood.com"
                  target="_blank"
                >
                  SMALLNEIGHBORHOOD
                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()} Copyright {customer.name} All Rights Reserved.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
